import React from 'react';
import Header from "../components/header/header.js";
import Form from "../components/form/Form.js"
import Footer from "../components/footer/footer.js";

const signup = () => {
  return (
    <div>
     <Header/>
     <Form/>
     <Footer/>
    </div>
  );
};

export default signup;
