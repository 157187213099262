import React from 'react';
import Header from "../components/header/header.js";
import LForm from "../components/form/Lform.js"
import Footer from "../components/footer/footer.js";

const signup = () => {
  return (
    <div>
     <Header/>
     <LForm/>
     <Footer/>
    </div>
  );
};

export default signup;
